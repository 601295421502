<template>
    <div class="row mb-3">
        <div class="col-12">
            <div class="card course-card mw-100 mh-auto p-0 shadow-xss border-0 rounded-lg overflow-hidden">
                <div class="card-header bg-current">
                    <h1 class="card-title fw-600 text-white">Headline</h1>
                </div>
                <div class="card-body">
                    <form @submit.prevent="postHeadline()" class="row">
                        <div class="form-group col-lg-6">
                            <label for="">Title</label>
                            <input type="text" v-model="headline.title" class="form-control" placeholder="Insert headline title">
                        </div>
                        <div class="form-group col-lg-6">
                            <label for="">Sub Title</label>
                            <input type="text"  v-model="headline.sub_title" class="form-control" placeholder="Insert headline sub title">
                        </div>
                        <div class="col-lg-4">
                            <div class="row">
                                <div class="form-group col-12">
                                    <label for="">First Sub Text Title</label>
                                    <input type="text" v-model="headline.st_first_title" class="form-control" placeholder="Insert headline first sub text title">
                                </div>
                                <div class="form-group col-12">
                                    <label for="">First Sub Text Description</label>
                                    <textarea class="form-control" v-model="headline.st_first_description" placeholder="Insert headline first sub text description"></textarea>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="row">
                                <div class="form-group col-12">
                                    <label for="">Second Sub Text Title</label>
                                    <input type="text" v-model="headline.st_second_title" class="form-control" placeholder="Insert headline second sub text title">
                                </div>
                                <div class="form-group col-12">
                                    <label for="">Second Sub Text Description</label>
                                    <textarea class="form-control" v-model="headline.st_second_description" placeholder="Insert headline second sub text description"></textarea>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="row">
                                <div class="form-group col-12">
                                    <label for="">Third Sub Text Title</label>
                                    <input type="text" v-model="headline.st_third_title" class="form-control" placeholder="Insert headline third sub text title">
                                </div>
                                <div class="form-group col-12">
                                    <label for="">Third Sub Text Description</label>
                                    <textarea class="form-control" v-model="headline.st_third_description" placeholder="Insert headline third sub text description"></textarea>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 text-right">
                            <button class="btn btn-current" :class="{'disabled': isLoad}" :disabled="isLoad">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
export default {
    data() {
        return {
            isLoad: true,
            headline: {
                id: '',
                title: '',
                sub_title: '',
                st_first_title: '',
                st_first_description: '',
                st_second_title: '',
                st_second_description: '',
                st_third_title: '',
                st_third_description: '',
            }
        }
    },
    created() {
        this.getHeadline()
    },
    methods: {
        async getHeadline() {
            await axios.get(`${process.env.VUE_APP_URL_API}/guest/blog/article/headline`, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                if(res.data.success) {
                    this.headline = res.data.data
                }
            }).catch((err) => console.error(err.response)).finally(() => this.isLoad = false)
        },
        async postHeadline() {
            let data = this.headline
            await axios.post(`${process.env.VUE_APP_URL_API}/admin/blog/article/headline`, data, {
                headers: {
                    'Authorization': `Bearer ${localStorage.token}`
                },
            }).then(res => {
                this.$swal({
                    toast: true,
                    title: 'Headline',
                    text: res.data.message,
                    icon: 'success',
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                })
                this.isLoad = true
                this.getHeadline()
            }).catch(err => {
                if (err.response.status == 422) {
                    this.$swal({
                        toast: true,
                        title: 'Headline',
                        text: err.response.data.message[0],
                        icon: 'error',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            })
        }
    }
}
</script>