<template>
    <div class="row">
        <div class="col-12">
            <Headline/>
            <div class="row">
                <div class="col-12">
                    <div class="card course-card mw-100 mh-auto p-0 shadow-xss border-0 rounded-lg overflow-hidden">
                        <div class="card-header bg-current">
                            <h1 class="card-title fw-600 text-white">Article</h1>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div  class="col-lg-10">
                                    <input type="text" class="form-control" placeholder="Search..." v-model="tableFilter">
                                </div>
                                <div  class="col-lg-2">
                                    <router-link :to="{name: 'BlogCreateAdmin'}" class="btn btn-current btn-lg btn-block">Create</router-link>
                                </div>
                                <div class="col-12 py-3">
                                    <div class="table-responsive mw-100 px-1">
                                        <datatable :class="'table table-hover table-bordered table-sm w-100'" :columns="columns" :filter="tableFilter" :data="data" :page="1" :perPage="10">
                                            <template name="default" slot-scope="{ row, index }">
                                                <tr v-if="row">
                                                    <td class="text-center">{{ index+1 }}</td>
                                                    <td class="text-center">
                                                        <div class="text-center">
                                                            <img :src="row.thumbnail" width="100" class="rounded" alt="...">
                                                        </div>
                                                    </td>
                                                    <td class="text-center">{{ row.title }}</td>
                                                    <td class="text-center">{{ row.created_name }}</td>
                                                    <td class="text-center">
                                                        <button @click="changeStatusAlert(row.id)" class="btn btn-sm" :class="{'btn-success' : row.publish, 'btn-danger' : !row.publish }"><i class="fas" :class="{'fa-check' : row.publish, 'fa-times' : !row.publish }"></i></button>
                                                    </td>
                                                    <td class="text-center">
                                                        <router-link :to="{name: 'BlogScrAdmin', params: {idArticle: row.id}}"><i class="ti-info font-xs text-grey-500 mr-4"></i></router-link>
                                                        <router-link :to="{name: 'BlogEditAdmin', params: {idArticle: row.id}}"><i class="ti-pencil font-xs text-grey-500 mr-4"></i></router-link>
                                                        <a href="javascript:void(0)" @click="deleteAlert(row.id)"><i class="ti-trash font-xs text-grey-500"></i></a>
                                                    </td>
                                                </tr>
                                                <tr v-else>
                                                    <td colspan="8">Nothing to see here</td>
                                                </tr>
                                            </template>
                                        </datatable>
                                    </div>
                                    <datatable-pager v-model="page" type="abbreviated"></datatable-pager>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> 
</template>
<script>
import Headline from './Headline.vue'
import axios from 'axios'
export default {
    components: {
        Headline
    },
    data(){
        return{
            media: process.env.VUE_APP_URL_CLOUD,
            data: [],
            page: 1,
            tableFilter: '',
            columns: [
                {label: 'No', field: 'id', headerClass: 'border-0 bg-current text-white py-3', class: 'product-thumbnail text-center', filterable: false, sortable: false},
                {label: 'Thumbnail', headerClass: 'border-0 bg-current text-white py-3', class: 'product-p text-center', filterable: false, sortable: false},
                {label: 'Title', field: 'title', headerClass: 'border-0 bg-current text-white py-3', class: 'product-price-wrapper text-center'},
                {label: 'Created By', field: 'created_by.name', headerClass: 'border-0 bg-current text-white py-3', class: 'product-price-wrapper text-center'},
                {label: 'Publish', headerClass: 'border-0 bg-current text-white py-3', class: 'product-price-wrapper text-center', filterable: false, sortable: false},
                {label: 'Option', headerClass: 'border-0 bg-current text-white py-3', class: 'product-price-wrapper text-center', filterable: false, sortable: false},
            ]
        }
    },
    created(){
        this.getArticle()
    },
    methods:{
        async getArticle(){
            await axios.get(`${process.env.VUE_APP_URL_API}/admin/blog/article`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.token}`
                },
            }).then(res => {
                this.data = res.data.data
            })
        },
        deleteAlert(id) {
            this.$swal({
                title: 'Are you sure?',
                text: 'You can\'t revert your action',
                showCancelButton: true,
                confirmButtonText: 'Yes Delete it!',
                cancelButtonText: 'No, Keep it!',
                showCloseButton: true,
                showLoaderOnConfirm: true,
                preConfirm: async() => {
                    this.deleteArticle(id)
                },
            }).then((result) => {
                if(result.isConfirmed) {
                    this.$swal({
                        toast: true,
                        title: 'Article',
                        text: 'Your data has been deleted !',
                        icon: 'success',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                } else {
                    this.$swal({
                        toast: true,
                        title: 'Cancelled',
                        text: 'Your data is still intact',
                        icon: 'info',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            })
        },
        async deleteArticle(id){
            var data = {
                id: id,
                _method: 'delete'
            }
            await axios.post(`${process.env.VUE_APP_URL_API}/admin/blog/article`, data, {
                headers: {
                    'Authorization': `Bearer ${localStorage.token}`
                },
            }).then(() => {
                this.getArticle()
            }).catch(() => {
                this.getArticle()
            })
        },
        changeStatusAlert(id){
            this.$swal({
                title: 'Are you sure?',
                text: 'You can\'t revert your action',
                showCancelButton: true,
                confirmButtonText: 'Yes Change Status it!',
                cancelButtonText: 'No, Keep it!',
                showCloseButton: true,
                showLoaderOnConfirm: true
                }).then(async (result) => {
                if(result.value) {
                    var data = {
                        _method: 'patch',
                        id: id
                    }
                    await axios.post(`${process.env.VUE_APP_URL_API}/admin/blog/article/publish`, data, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        }
                    }).then(res => {
                        this.$swal({
                            toast: true,
                            title: 'Article',
                            text: res.data.message,
                            icon: 'success',
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                        })
                        this.getArticle()
                    }).catch(err => {
                        if (err.response.status == 422) {
                            this.$swal({
                                toast: true,
                                title: 'Article',
                                text: err.response.data.message[0],
                                icon: 'error',
                                position: 'top-end',
                                showConfirmButton: false,
                                timer: 3000,
                                timerProgressBar: true,
                            })
                        }
                    })
                } else {
                    this.$swal({
                        toast: true,
                        title: 'Cancelled',
                        text: 'Your data is still intact',
                        icon: 'info',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            })
        },
    },
}
</script>
